<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { GlobeAltIcon, LanguageIcon } from '@heroicons/vue/24/solid'
import { SlashIcon } from '@radix-icons/vue'
import CampaignBasicABTesting from './CampaignBasicABTesting.vue'
import Button from '@/components/ui/button/Button.vue'
import Label from '@/components/ui/label/Label.vue'
import Input from '@/components/ui/input/Input.vue'
import ErrorMessage from '@/components/ui/error-message/ErrorMessage.vue'
import Field from '@/components/ui/field/Field.vue'
import CampaignBasicToggleNetwork from '@/components/partials/campaign/CampaignBasicToggleNetwork.vue'
import CampaignBasicToggleDevice from '@/components/partials/campaign/CampaignBasicToggleDevice.vue'
import browserLanguages from '@/static/browserLanguages'
import { countryList } from '@/static/countryList'
import Multiselect from '@/components/base/MultiSelect.vue'
import CampaignDomainSelect from '@/components/partials/campaign/CampaignDomainSelect.vue'
import CampaignPageTypeRadio from '@/components/partials/campaign/CampaignPageTypeRadio.vue'
import { Switch } from '@/components/ui/switch'
import { generateQueryString } from '@/helpers/query-string'
import CampaignEmptyDomainAlert from '@/components/partials/campaign/CampaignEmptyDomainAlert.vue'
import { domainService } from '@/services/domain'

const props = defineProps({
  campaign: {
    type: Object,
    required: false,
    default: () => ({
      name: '',
      active: true,
      alias: null,
      domain: null,
      pages: {
        white: {
          type: 'content',
          content: ''
        },
        offers: [
          {
            content: '',
            share: 100,
            type: 'content'
          }
        ]
      },
      filters: {
        deny_always: false,
        tiktok: false,
        proxy: false,
        google: false,
        facebook: false,
        bots: false,
        kwai: false,
        cloakup_ai: true,
        adspy: false,
        geolocation: {
          allow: true,
          countries: []
        },
        device: {
          allow: true,
          devices: []
        },
        referer: {
          block_null: false,
          allow: true,
          domains: []
        },
        query: {
          allow: true,
          params: {},
          condition: 'some',
          remove_params: true,
          rules: {}
        },
        domain: {
          allow: true,
          domains: []
        },
        browser_language: {
          allow: true,
          languages: []
        },
        isp: {
          allow: true,
          isps: []
        },
        os: {
          allow: true,
          os: []
        },
        user_agent: {
          allow: true,
          user_agents: []
        },
        blacklist: [],
        whitelist: []
      }
    })
  },
  errors: {
    type: Object,
    required: false,
    default: () => ({})
  },
  isLoading: {
    type: Boolean,
    required: true,
    default: false
  },
  mode: {
    type: String,
    required: false,
    default: 'create'
  }
})

const emit = defineEmits(['submit'])

const { locale } = useI18n()

const form = reactive({
  ...props.campaign
})
const errors = ref(props.errors)
const domains = ref(undefined)
const isLoadingDomains = ref(false)
const abTesting = ref(false)

const countriesMapped = computed(() => {
  return countryList.map((country) => {
    const key = locale.value !== 'pt-BR' ? 'name_int' : 'name'
    return {
      ...country,
      name: country[key]
    }
  }).sort((a, b) => a.name.localeCompare(b.name))
})
const browserLanguagesMapped = computed(() => {
  return browserLanguages.map((language) => {
    const key = locale.value !== 'pt-BR' ? 'name_int' : 'name'
    return {
      ...language,
      name: language[key]
    }
  }).sort((a, b) => a.name.localeCompare(b.name))
})

function validate() {
  errors.value = {}
  const notSelectedAnyOf = ['facebook', 'tiktok', 'google', 'kwai']

  if (!notSelectedAnyOf.some(platform => form.filters[platform])) {
    errors.value = {
      ...errors.value,
      networks: 'Por favor, selecione alguma rede social'
    }
  }

  if (form.filters.device.devices.length === 0) {
    errors.value = {
      ...errors.value,
      device: {
        devices: 'Por favor, selecione pelo menos um dispositivo'
      }
    }
  }

  return Object.keys(errors.value).length === 0
}

async function onSubmit() {
  if (!validate()) {
    return
  }

  const formatOffersPage = abTesting.value
    ? form.pages.offers
      .map(item => ({
        type: item.type,
        content: item.content,
        share: Number(item.share)
      }))
    : [
        {
          content: form.pages.offers[0].content,
          share: 100,
          type: form.pages.offers[0].type
        }
      ]

  const params = Object.keys(form.filters.query.params).length ? form.filters.query.params : generateQueryString()
  const rules = Object.keys(params).reduce((acc, param) => {
    acc[param] = 'equals'
    return acc
  }, {})

  const payload = {
    name: form.name,
    domain: form.domain,
    alias: form.alias,
    active: form.active,
    mode: 'basic',
    pages: {
      white: {
        type: form.pages.white.type,
        content: form.pages.white.content
      },
      offers: formatOffersPage
    },
    filters: {
      tiktok: form.filters.tiktok,
      google: form.filters.google,
      facebook: form.filters.facebook,
      kwai: form.filters.kwai,
      cloakup_ai: form.filters.cloakup_ai,
      adspy: form.filters.adspy,
      bots: form.filters.bots,
      proxy: form.filters.proxy,
      deny_always: form.filters.deny_always,
      geolocation: {
        allow: true,
        countries: form.filters.geolocation.countries
      },
      device: {
        allow: true,
        devices: form.filters.device.devices
      },
      referer: {
        block_null: false,
        allow: true,
        domains: []
      },
      query: {
        allow: true,
        params,
        condition: 'some',
        remove_params: true,
        rules
      },
      domain: {
        allow: true,
        domains: []
      },
      browser_language: {
        allow: true,
        languages: form.filters.browser_language.languages
      },
      isp: {
        allow: true,
        isps: []
      },
      os: {
        allow: true,
        os: []
      },
      user_agent: {
        allow: true,
        user_agents: []
      },
      blacklist: [],
      whitelist: []
    }
  }

  emit('submit', payload)
}

async function fetchDomains() {
  isLoadingDomains.value = true

  try {
    const { data } = await domainService.findAll({
      page: 1,
      limit: 100
    })
    domains.value = data?.data.filter(domain => domain.verified) || []
  } catch (error) {
    console.error(error)
  } finally {
    isLoadingDomains.value = false
  }
}

onMounted(() => {
  fetchDomains()

  form.name = props.campaign.name
  form.active = props.campaign.active
  form.alias = props.campaign.alias
  form.domain = props.campaign.domain

  // pages
  form.pages.white.content = props.campaign.pages.white.content
  form.pages.white.type = props.campaign.pages.white.type
  form.pages.offers = props.campaign.pages.offers

  if (props.campaign.pages.offers.length > 1) {
    abTesting.value = true
  }

  // filters
  form.filters.tiktok = props.campaign.filters?.tiktok ?? false
  form.filters.proxy = props.campaign.filters?.proxy ?? true
  form.filters.facebook = props.campaign.filters?.facebook ?? false
  form.filters.google = props.campaign.filters?.google ?? false
  form.filters.bots = props.campaign.filters?.bots ?? false
  form.filters.kwai = props.campaign.filters?.kwai ?? false
  form.filters.cloakup_ai = true
  form.filters.adspy = true

  if (props.campaign.filters?.cloakup) {
    form.filters.facebook = true
    form.filters.google = true
    form.filters.bots = true
    form.filters.cloakup_ai = true
  }

  form.filters.device.allow = true
  form.filters.device.devices = props.campaign.filters.device.devices

  form.filters.geolocation.allow = true
  form.filters.geolocation.countries = props.campaign.filters.geolocation.countries

  form.filters.query.allow = true
  form.filters.query.params = props.campaign.filters.query.params
  form.filters.query.condition = 'some'
  form.filters.query.remove_params = true
  form.filters.query.rules = props.campaign.filters.query.rules ?? {}

  form.filters.browser_language.allow = true
  form.filters.browser_language.languages = props.campaign.filters.browser_language?.languages ?? []

  form.filters.whitelist = props.campaign.filters?.whitelist ?? []

  // filtros não usados pela configuração básica

  form.filters.deny_always = false
  form.filters.referer.block_null = false

  form.filters.referer.allow = true
  form.filters.referer.domains = []

  form.filters.isp.allow = true
  form.filters.isp.isps = []

  form.filters.user_agent.allow = true
  form.filters.user_agent.user_agents = []

  form.filters.domain.allow = true
  form.filters.domain.domains = []

  form.filters.os.allow = true
  form.filters.os.os = []

  form.filters.blacklist = []
})

watch(() => props.errors, () => {
  errors.value = props.errors
})
</script>

<template>
  <div>
    <form @submit.prevent="onSubmit">
      <fieldset :disabled="isLoading">
        <div class="grid max-w-lg grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="col-span-6">
            <Field>
              <Label
                required
                for="name"
              >
                {{ $t('common.name') }}
              </Label>
              <Input
                id="name"
                v-model="form.name"
                name="name"
                type="text"
                required
              />
              <ErrorMessage :message="errors?.name" />
            </Field>
          </div>
          <div class="col-span-6">
            <div class="space-y-4">
              <Field dir="row">
                <Switch
                  id="active"
                  v-model:checked="form.active"
                />
                <Label for="active">
                  {{ `${$t('components.campaign_save_form.campaign_status')} (${form.active ? $t('components.campaign_status_badge.active')
                    : $t('components.campaign_status_badge.disabled')
                  })` }}
                </Label>
              </Field>
            </div>
          </div>

          <div class="col-span-6 space-y-4">
            <Field>
              <Label
                for="white_page"
                required
              >
                {{ $t('components.campaign_save_form.safe_page.title') }}
              </Label>
              <Input
                id="white_page"
                v-model="form.pages.white.content"
                name="white_page"
                type="url"
                placeholder="https://"
                required
              />
              <ErrorMessage
                v-if="errors?.pages?.white"
                :message="errors?.pages?.white"
              />
            </Field>
            <Field>
              <Label>
                {{ $t('exhibition_page_method') }}
              </Label>
              <CampaignPageTypeRadio
                v-model="form.pages.white.type"
                page="white"
              />
            </Field>
          </div>
          <div
            v-if="abTesting"
            class="col-span-6 space-y-4"
          >
            <div class="flex justify-end">
              <Field dir="row">
                <Switch
                  id="ab-testing"
                  v-model:checked="abTesting"
                />
                <Label
                  for="ab-testing"
                >
                  {{ $t('components.campaign_save_form.ab_testing') }}
                </Label>
              </Field>
            </div>
            <CampaignBasicABTesting
              v-model="form.pages.offers"
              :errors="errors"
            />
          </div>
          <div
            v-else
            class="col-span-6 space-y-4"
          >
            <Field>
              <div class="flex items-center justify-between gap-2">
                <Label
                  for="offer_page"
                  required
                >
                  {{ $t('components.campaign_save_form.offer_page.title') }}
                </Label>
                <Field dir="row">
                  <Switch
                    id="ab-testing"
                    v-model:checked="abTesting"
                  />
                  <Label
                    for="ab-testing"
                  >
                    {{ $t('components.campaign_save_form.ab_testing') }}
                  </Label>
                </Field>
              </div>
              <Input
                id="offer_page"
                v-model="form.pages.offers[0].content"
                name="offer_page"
                type="url"
                placeholder="https://"
                required
              />

              <ErrorMessage
                v-if="errors?.pages && errors?.pages[`offers[0]`].length"
                :message="errors.pages[`offers[0]`]"
              />
            </Field>
            <Field>
              <Label>
                {{ $t('exhibition_page_method') }}
              </Label>
              <CampaignPageTypeRadio
                v-model="form.pages.offers[0].type"
                page="offers[0]"
              />
            </Field>
          </div>
          <div class="col-span-6 grid grid-cols-1 gap-x-6 gap-y-4  sm:grid-cols-2">
            <Field>
              <Label
                for="domain"
                required
              >
                {{ $t('common.domain') }}
              </Label>
              <CampaignDomainSelect
                id="domain"
                v-model="form.domain"
                :domains="domains"
                name="domain"
                required
                :disabled="isLoadingDomains || !domains?.length"
              />
              <ErrorMessage
                v-if="errors?.domain"
                :message="errors?.domain"
              />
            </Field>
            <div>
              <Field>
                <Label
                  for="alias"
                  required
                >
                  Slug
                </Label>
                <div class="relative">
                  <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <SlashIcon class="size-4 text-neutral-400" />
                  </div>
                  <Input
                    id="alias"
                    v-model="form.alias"
                    name="alias"
                    placeholder="oferta"
                    required
                    class="py-1.5 pl-10"
                    :disabled="isLoadingDomains || !domains?.length"
                  />
                </div>
                <ErrorMessage
                  v-if="errors?.alias"
                  :message="errors?.alias"
                />
              </Field>
            </div>
            <div
              v-if="domains && !domains?.length"
              class="col-span-6"
            >
              <CampaignEmptyDomainAlert variant="info" />
            </div>
          </div>
          <div class="col-span-6">
            <Field>
              <Label
                for="network"
                required
              >
                Network
              </Label>
              <div class="flex">
                <CampaignBasicToggleNetwork v-model="form.filters" />
              </div>
              <ErrorMessage
                v-if="errors?.networks"
                :message="errors?.networks"
              />
            </Field>
          </div>
          <div class="col-span-6">
            <Field>
              <Label
                for="device"
                required
              >
                {{ $t('device') }}
              </Label>
              <div class="flex">
                <CampaignBasicToggleDevice v-model="form.filters.device.devices" />
              </div>
              <ErrorMessage
                v-if="errors?.device?.devices"
                :message="errors?.device?.devices"
              />
            </Field>
          </div>
          <div class="col-span-6">
            <Field>
              <Label
                for="geolocation"
              >
                {{ $t('location') }}
              </Label>
              <div class="flex">
                <Multiselect
                  v-model="form.filters.geolocation.countries"
                  :options="countriesMapped"
                  :icon="GlobeAltIcon"
                  :placeholder="$t('components.campaign_save_form.filters.geolocation.select.placeholder')"
                  :empty="$t('components.campaign_save_form.filters.geolocation.select.empty')"
                />
              </div>
              <ErrorMessage
                v-if="errors?.country?.countries"
                :message="errors?.country?.countries"
              />
            </Field>
          </div>
          <div class="col-span-6">
            <Field>
              <Label
                for="browser_language"
              >
                {{ $t('browser_language') }}
              </Label>
              <div class="flex">
                <Multiselect
                  v-model="form.filters.browser_language.languages"
                  :options="browserLanguagesMapped"
                  :icon="LanguageIcon"
                  :placeholder="$t('components.campaign_save_form.filters.browser_language.select.placeholder')"
                  :empty="$t('components.campaign_save_form.filters.browser_language.select.empty')"
                />
              </div>
              <ErrorMessage
                v-if="errors?.browser_language?.languages"
                :message="errors?.browser_language?.languages"
              />
            </Field>
          </div>
        </div>

        <div class="mt-10 flex gap-2">
          <Button
            type="submit"
            :loading="isLoading"
          >
            {{
              $t('common.save')
            }}
          </Button>
        </div>
      </fieldset>
    </form>
  </div>
</template>
