<script setup>
import { onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { toast } from 'vue-sonner'
import { EnvelopeIcon } from '@heroicons/vue/24/solid'
import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import Button from '@/components/ui/button/Button.vue'
import { authService } from '@/services/auth'
import { useAuthStore } from '@/stores/auth'
import Input from '@/components/ui/input/Input.vue'
import ErrorMessage from '@/components/ui/error-message/ErrorMessage.vue'
import Label from '@/components/ui/label/Label.vue'
import Field from '@/components/ui/field/Field.vue'
import PasswordInput from '@/components/common/PasswordInput.vue'
import { useGrecaptcha } from '@/composables/useGrecaptcha'
import PageHeading from '@/components/common/PageHeading.vue'

const { t } = useI18n()
useHead({
  title: t('pages.auth.sign_in.title')
})
const router = useRouter()
const route = useRoute()
const authStore = useAuthStore()

const form = reactive({
  email: '',
  password: '',
})
const errors = ref({})
const isLoading = ref(false)

async function onSubmit() {
  isLoading.value = true

  try {
    const token = await useGrecaptcha()
    await login({
      email: form.email,
      password: form.password,
      token
    })
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
    isLoading.value = false
  }
}

async function login({ email, password, token }) {
  try {
    await authService.signIn({
      email,
      password,
      token
    })

    authStore.token = '1'
    await authStore.checkAuth()

    const redirectTo = route.query.redirect_to
    if (redirectTo) {
      await router.push(redirectTo)
    } else {
      await router.push({
        name: 'Dashboard'
      })
    }
  } catch (error) {
    if (error.response?.status === 422) {
      errors.value = error.response?.data?.data
    }

    toast.error(error.response?.data?.message || error.message, {
      description: error.response?.data?.action,
    })
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <div>
    <div>
      <img
        class="h-12 w-auto dark:invert"
        src="../../assets/img/logo-black.svg"
        alt="Cloakup"
      >
      <PageHeading
        :title="$t('pages.auth.sign_in.title')"
        class="mt-6"
      />
      <p class="mt-2 text-sm">
        <span class="text-muted-foreground">
          {{ $t('common.or') }}
        </span>
        {{ " " }}

        <router-link
          :to="{ name: 'SignUp', query: route.query }"
          class="font-medium "
        >
          {{ $t("pages.auth.sign_in.sign_up") }}
        </router-link>
      </p>
    </div>

    <div class="mt-8">
      <form @submit.prevent="onSubmit">
        <fieldset
          :disabled="isLoading"
          class="space-y-4"
        >
          <div>
            <Field>
              <Label
                for="email"
              >
                {{ $t("common.email") }}
              </Label>
              <Input
                id="email"
                v-model="form.email"
                name="email"
                type="email"
                autocomplete="email"
                required
              >
                <template #leading>
                  <EnvelopeIcon class="text-muted-secondary size-5" />
                </template>
              </Input>
              <ErrorMessage
                :message="errors?.email"
              />
            </Field>
          </div>

          <div>
            <Field>
              <Label
                for="password"
              >
                {{ $t("common.password") }}
              </Label>
              <PasswordInput
                id="password"
                v-model="form.password"
                name="password"
                autocomplete="current-password"
                required
              />

              <ErrorMessage
                :message="errors?.password"
              />
            </Field>
          </div>
          <div class="flex justify-end">
            <router-link
              :to="{ name: 'ForgotPassword' }"
              class="hover: text-sm  font-medium"
            >
              {{ $t("pages.auth.sign_in.forgot_password") }}
            </router-link>
          </div>
          <div>
            <Button
              type="submit"
              block
              :loading="isLoading"
            >
              {{ $t("pages.auth.sign_in.submit") }}
            </Button>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</template>
